import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class SeccionNoticiaService {
	private noticiaRef: firebase.firestore.CollectionReference; 
	private firestoreMarcasPath: string;

  	constructor(private firestore: AngularFirestore,
    private firestorage: AngularFireStorage) {
    	this.noticiaRef = firebase.firestore().collection(`${environment.firestoreEnviroment}/seccion-noticia`);
    	this.firestoreMarcasPath = `${environment.firestoreEnviroment}/seccion-noticia`;
  	}

  	getAll(){
		return this.noticiaRef.get().then(querySnapshot => {
			let data = querySnapshot.docs.map(x  => Object.assign(x.data(), {id: x.id}))
			return data
		})
	}
	update(id: string, payload: any){
	    return this.noticiaRef.doc(id).update(payload)
	}
	upload(noticia,file){
		return Promise.resolve(
			this.firestorage.upload(`${environment.firestoreEnviroment}/seccion-noticia/${file.name}`, file).then(_ => {
		      	return this.firestorage.ref(`${environment.firestoreEnviroment}/seccion-noticia/${file.name}`).getDownloadURL().toPromise();
		     })
		);
	    
	}
}
