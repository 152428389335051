import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { SeccionnoticiasComponent } from './seccion-noticias/seccion-noticias.component';
import { VideosComponent, SafePipe } from './videos/videos.component';
import { TrainingComponent, SafePipeT } from './training/training.component';
import { MaterialesComponent } from './materiales/materiales.component';
import { ComunicadosComponent } from './comunicados/comunicados.component';
import { AgentesMinoristasComponent } from './agentes-minoristas/agentes-minoristas.component';
import { BannersComponent } from './banners/banners.component';
import { AuthService } from './core/auth.service';
import { AuthGuard } from './core/auth.guard';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { VideoService } from './services/index.services';
import { TrainingService } from './services/index.services';
//import { TrainingBannerService } from './services/index.services';
import { NoticiasService } from './services/index.services';
import { MaterialesService } from './services/index.services';
import { ComunicadosService } from './services/index.services';
import * as firebase from 'firebase';
import { ModalComponent } from './shared/modal/modal.component';
import { ClientesService } from './services/clientes.service';
import { MayoristasService } from './services/mayoristas.service';
import { AlertaService } from './services/alerta.service';
import { OrderModule } from 'ngx-order-pipe';
import { PromocionesComponent } from './promociones/promociones.component';
import { PdfAdministrablesComponent } from './pdf-administrables/pdf-administrables.component';
import { MarcasComponent } from './marcas/marcas.component';
import { BannerV2Service } from './services/banner-v2.service';
import { GenericV2Service } from './services/generic-v2.service';

firebase.initializeApp(environment.firebase);
firebase.firestore().settings(environment.firestoreSetting);

const appRoutes: Routes = [{
  path     : '',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  children : [{
    path: 'marcas',
    component: MarcasComponent
  },{
    path: 'pdfs',
    component: PdfAdministrablesComponent
  },{
    path     : 'noticias',
    component: NoticiasComponent
  },{
    path     : 'seccion-noticias',
    component: SeccionnoticiasComponent
  },{
    path     : 'videos',
    component: VideosComponent
  },{
    path     : 'training',
    component: TrainingComponent
  },{
    path     : 'materiales',
    component: MaterialesComponent
  },{
    path     : 'comunicados',
    component: ComunicadosComponent
  },{
    path     : 'banners',
    component: BannersComponent
  },{
    path     : 'clientes',
    component: AgentesMinoristasComponent
  },{
    path     : '',
    redirectTo: '/banners',
    pathMatch: 'full'
  },{
    path     : 'promociones',
    component: PromocionesComponent
  }]
},{
	path 		 : 'login' ,
	component: LoginComponent
}];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NoticiasComponent,
    SeccionnoticiasComponent,
    VideosComponent,
    TrainingComponent,
    MaterialesComponent,
    ComunicadosComponent,
    AgentesMinoristasComponent,
    BannersComponent,
    ModalComponent,
    SafePipe,
    SafePipeT,
    PromocionesComponent,
    PdfAdministrablesComponent,
    MarcasComponent,
  ],
  imports: [
    BrowserModule,
    OrderModule,
    RouterModule.forRoot(
      appRoutes,
      {useHash:true}
    ),
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    NoticiasService,
    VideoService,
    AlertaService,
    MaterialesService,
    ComunicadosService,
    ClientesService,
    MayoristasService,
    GenericV2Service,
    BannerV2Service,
    TrainingService/*,
    TrainingBannerService*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
