import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Banner } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BannerV2Service extends GenericV2Service<Banner>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore);
    super.setActionUrl('banners')
  }

  getBannerbyMarc(marc: string): Promise<Banner> {
    return super.getAll().then(banners => banners.filter(x => x.marc === marc)).then(x => x[0])
  }

  getImagebyMarc(marc: string): Promise<string> {
    return super.getAll().then(banners => banners.filter(x => x.marc == marc)).then(filter => filter[0].urlFile.toString())
  }

  deleteById(id: string): Promise<any> {
    return super.delete(id);
  }

  addBanner(document): Promise<any> {
    return super.add(document)
  }
}
