import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterialesService } from '../services/index.services';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import { environment } from 'src/environments/environment';

moment.locale('es');

@Component({
  selector: 'app-materiales',
  templateUrl: './materiales.component.html',
  styleUrls: ['./materiales.component.css']
})
export class MaterialesComponent implements OnInit {

	  materialForm : FormGroup;
    materialFile : any;
    success     : boolean = false;
    loading     : boolean = false;
    materiales    : any = [];

  	constructor(
  		public modalService: ModalService,
  		private fb: FormBuilder,
      private s: MaterialesService
  	) { }

  ngOnInit() {
  		this.materialForm = this.fb.group({
        titulo: ['',Validators.required],  
        marca: ['',Validators.required], 
        fecha: ['',Validators.required],       
        file: [null, Validators.required]
      })        
      this.getAllMateriales();
  		this.modalService.buildModal(false,'Agregar Material',this.materialForm.controls,true);
  }

  getAllMateriales(){
    this.s.getAll().then( r =>{     
      _.forEach(r, e =>{
        e.dateFormated = moment.utc(e.datePost.toDate()).format('LL')
      })  
      r = _.orderBy(r,['marca','datePost'],['asc','desc']);       
      this.materiales = r;
    })
  }

  updateFile($event){    
    if($event.target.files && $event.target.files[0]){
      this.materialFile = $event.target.files[0];
    }
  }  

  postMaterial(){        
    if(this.materialForm.value){
      this.loading = true;
      let urlRef = `${environment.firestoreEnviroment}`+'/materiales/' + this.materialFile.name;
      let ref = firebase.storage().ref().child(urlRef);      
      ref.put(this.materialFile).then( snapshot => {
        snapshot.ref.getDownloadURL().then( urlUploaded => {
          this.s.add({
            titulo     : this.materialForm.controls.titulo.value,            
            documentUrl: urlUploaded,
            datePost   : new Date(this.materialForm.controls.fecha.value),
            marca: this.materialForm.controls.marca.value,
            urlRef
          }).then( r => {
            console.log(r)
            this.materialForm.reset();
            this.modalService.openModal();
            this.success = true;
            this.loading = false;
            this.getAllMateriales();
          }).catch( e => {
             this.loading = false;
          })
        })
      }).catch( e => {
        this.loading = false;
      })
    }
  } 

  deletePost(material){
    this.loading = true;
    if(material){
      let ref = firebase.storage().ref().child(material.urlRef);
      ref.delete().then( () => {
        this.s.delete(material.id).then( r => {
          this.loading = false;
          this.success = true;
          this.getAllMateriales();
        }).catch( e => {
          this.loading = false;        
        })
      }).catch( () => {
        this.loading = false;
      })        
    }
  }

  private buildForm(){
  	this.materialForm = this.fb.group({
  		titulo: ['',Validators.required],
  		marca: ['',Validators.required],
      fecha: ['',Validators.required],
  	})
  }

}
