import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GenericV2Service<T> {
  protected actionUrl;
  protected firebaseCollection : AngularFirestoreCollection<T>;

  constructor(protected firestore: AngularFirestore) { }



  setActionUrl(str: string): GenericV2Service<T> {
    this.actionUrl = str;
    this.firebaseCollection = this.firestore.collection<T>(`${environment.firestoreEnviroment}/`+this.actionUrl);
    return this;
  }

  getAll(): Promise<T[]> {
    return this.firebaseCollection.get().toPromise().then(querySnapshot => {
      let data: T[] = querySnapshot.docs.map(x  => Object.assign(x.data() as T, {id: x.id}))
      return data
    })
  }

  get(id: string): Promise<T> {
    return this.firebaseCollection.doc<T>(id).get().pipe(
      map(documentSnapshot => Object.assign(documentSnapshot.data() as T, {id: documentSnapshot.id}))
    ).toPromise()
  }

  add(document: T) : Promise<any> {
    return this.firebaseCollection.add(document)
  }

  delete(id: string): Promise<any> {
    return this.firebaseCollection.doc(id).delete();
  }

  update(id: string, payload: any): Promise<any> {
    return this.firebaseCollection.doc(id).update(payload)
  }
}
