import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Training } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TrainingService extends GenericV2Service<Training>{

  constructor(firestore: AngularFirestore) {
    super(firestore)
    super.setActionUrl('training')
  }
}
