import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { Clientes } from '../models/index.models';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientesService } from '../services/index.services';
import { MayoristasService } from '../services/index.services';
import * as firebase from 'firebase';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as  XLSX from 'xlsx';
import * as sha1 from 'js-sha1';

@Component({
  selector: 'app-agentes-minoristas',
  templateUrl: './agentes-minoristas.component.html',
  styleUrls: ['./agentes-minoristas.component.scss']
})
export class AgentesMinoristasComponent implements OnInit {

	  success     : boolean = false;
  	loading     : boolean = false;
  	clientes    : any;
  	modalType = 'minorista';
  	view = 'minorista';
  	minoristaForm: FormGroup;
  	mayoristaForm: FormGroup;

  constructor(
  	public modalService: ModalService,
  	private fb: FormBuilder,
    private s: ClientesService,
    private s2: MayoristasService) { }

  ngOnInit() {
  	this.getAllClientes()
  	this.minoristaForm = this.fb.group({
  		nombre: ['',Validators.required],
  		apellido: ['',Validators.required],
  		correo: ['',Validators.required],
  		agencia: ['',Validators.required],
  		telefono: ['',Validators.required],
  		fecha_de_nacimiento: ['',Validators.required],
  	})
  	this.mayoristaForm = this.fb.group({
  		nombre: ['',Validators.required],
  		apellido: ['',Validators.required],
  		correo: ['',Validators.required],
  		agencia_nombre: ['',Validators.required],
  		password: ['',Validators.required],
      repeatPassword : ['',Validators.required],
  	})
  								// Vista del modal / Head del modal / Campos / Botones de accion
  	this.modalService.buildModal(false,'Agregar Minorista',this.minoristaForm.controls,true);
  }

  openModal(type){
  	console.log(type);
  	if(type == 'minorista'){
	  	this.modalType = 'minorista';
  		// Vista del modal / Head del modal / Campos / Botones de accion
	  	this.modalService.buildModal(true,'Agregar Minorista',this.minoristaForm.controls,true);
  	}else{
	  	this.modalType = 'mayorista';
  		// Vista del modal / Head del modal / Campos / Botones de accion
	  	this.modalService.buildModal(true,'Agregar Mayorista',this.mayoristaForm.controls,true);
  	}
  }

  changeView(view){
  	this.view = view;
  }

  getAllClientes(){
  	this.s.getAll().then( r => {
      _.forEach(r, e => {
        switch( typeof( e.fecha_nacimiento ) ){
          case 'string': e.fecha_nacimiento = moment.utc(moment(e.fecha_nacimiento).toDate()).format('LL'); break;
          case 'object': e.fecha_nacimiento = moment.utc(e.fecha_nacimiento.toDate()).format('LL')
        }
      })
      this.clientes = r;
    });
  }

  downloadData(){
    let dataToExport = _.cloneDeep(this.clientes);

    dataToExport = dataToExport.filter( e => {
      return e.tipo == 'minorista';
    })

    let headers = [];
    let body = [];

    for(let key in dataToExport[0]){
      if(this.validateColumn(key))
        headers.push(key);
    }
    console.log(dataToExport)

    _.forEach(dataToExport, e =>{
      let row : Array<string> = [];
      for(let key in e){
        if(this.validateColumn(key))
          row.push(e[key])
      }
      body.push(row)
    });

    dataToExport = [headers,...body];
    console.log(dataToExport)
    let ws : XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
    let wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');
    XLSX.writeFile(wb, 'AgentesMinoristas.xlsx')
    console.log('por aqui pase')
  }

  validateColumn(name){
    switch(name){
      case 'updated_at':
      case 'password':
      case 'id':
      case 'tipo':
      case 'invitador':
      case 'aviso_privacidad':
      case 'fecha_nacimiento':
      case 'created_at':
      case 'online': return false;
      default: return true;
    }
  }

  delete(id){
    if(id){
      this.loading = true;
      this.s2.delete(id).then(r => {
        this.success = true;
        this.loading = false;
        this.getAllClientes();
      });
    }
  }

  postMayorista(){
    if(this.mayoristaForm.value){
      this.loading = true;
     let payload = this.mayoristaForm.value;
     payload.password = sha1(payload.password)
     delete payload.repeatPassword;
     this.s2.add({
       ...payload,
       tipo: 'mayorista',
       created_at: moment().toDate(),
       aviso_privacidad: true,
       online: false,
     }).then(r =>{
       this.success = true;
       this.loading = false;
       this.mayoristaForm.reset();
       this.modalService.openModal();
       this.getAllClientes();
     })
    }
  }

}
