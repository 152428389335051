import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Clientes } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ClientesService extends GenericV2Service<Clientes>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore);
    super.setActionUrl('customers')
  }
}
