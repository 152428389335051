import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Mayorista } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class MayoristasService extends GenericV2Service<Mayorista>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore);
    super.setActionUrl('customers');
  	// super('customers')
  }
}
