import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
	authState : any = null;
	user: any = null;
	constructor(
		private af: AngularFireAuth,		
		private router: Router
	) { 		
		this.authState = this.af.auth.onAuthStateChanged(user => {
			console.log(user)
		  if (user) {        
		    this.user = user;  		    		    
		  }else{
		  	this.user = false;
		  }   
		});
	}
	// Returns true if user is logged in
	authenticated(): any {
		return this.user;
	}	
}
