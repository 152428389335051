import { Injectable } from '@angular/core';
import { ModalComponent } from '../shared/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class AlertaService {
  private _alerta: ModalComponent;
  
  constructor() { }

  get alerta(){
    return this._alerta;
  }

  set alerta(alerta: ModalComponent){
    this._alerta = alerta;
  }

}
