import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	constructor(
		private fb : FormBuilder,
		public afAuth: AngularFireAuth,
		private router: Router
	) { }

	ngOnInit() {
		this.loginForm = this.fb.group({
			email 		: ['', Validators.required],
			password 	: ['', Validators.required]
		})
	}

	login(){
		this.afAuth.auth.signInWithEmailAndPassword(this.loginForm.controls.email.value, this.loginForm.controls.password.value).then( r => {
			console.log( r );
			this.router.navigate(['/banners'])
		}).catch( e => {

		})
	}

}
