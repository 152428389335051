import { Component, OnInit, AfterViewInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { ModalService } from '../modal.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VideoService } from '../services/index.services';
import { Video } from '../models/index.models';
import * as firebase from 'firebase';
import { ModalComponent } from '../shared/modal/modal.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
	let id: any;
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length == 11) {
		id = match[2];
    }

	const urlFinal = `https://www.youtube.com/embed/${ id }`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(urlFinal);
  }
}

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit, AfterViewInit {
	// @ViewChild(ModalComponent) modal: ModalComponent;
	@ViewChildren(ModalComponent) modals: QueryList<ModalComponent>;
	private modalEdit: ModalComponent;
	public modalSuccess: ModalComponent;
	public modalVideo: ModalComponent;
	public urlVideoModal: string;
	private regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	private videoForm: FormGroup;
	private videoEditForm: FormGroup;
	public loading: boolean;
	public videos: Promise<Video[]>;

	constructor(
		public modalService: ModalService,
		private fb: FormBuilder,
		private videoService: VideoService
	) { }

	ngOnInit() {
		this.createVideoForm();
		this.createVideoEditForm();
		this.getAllVideos();
		this.urlVideoModal = 'https://www.youtube.com/embed?v=mGiFu6aOiFc';
		this.loading = false;
		// Vista del modal / Head del modal / Campos / Botones de accion
		this.modalService.buildModal(false,'Agregar Video',this.videoForm.controls,true);
	}

	ngAfterViewInit(): void {
		// this.modal.onConfirmacion = () => {
		// 	console.log('Hola estoy guardando...');
		// 	this.modal.hide();
		// }
		// this.urlVideoModal = 'https://www.youtube.com/watch?v=mGiFu6aOiFc';
		this.modalEdit = this.modals.find(modal => modal.identificador ==='miModal');
		this.modalSuccess = this.modals.find(modal => modal.identificador ==='miModalSuccess');
		this.modalVideo = this.modals.find(modal => modal.identificador ==='miModalVideo');
	}

	getAllVideos(){
		// this.videos = this.getVideos(); Test if loader is working getting all Videos
		this.videos = this.videoService.getAll();
	}

	// async getVideos(): Promise<Video[]>{
	// 	const videos:Video[] = [{
	// 		id: '985479hfkjfdhsd',
	// 		title: 'royalgg',
	// 		marca: 'marcaR',
	// 		url: 'urlasdasdas',
	// 		createdAt: '8473987dsfsd'
	// 	}];

	// 	return new Promise( (resolve,reject) => {
	// 		setTimeout(() => {
	// 			resolve(videos);
	// 		}, 20000);
	// 	});
	// }

	deleteVideo(video: Video){
		const { id } = video;
		this.videoService.delete(id)
			.then(() => {
				this.getAllVideos();
				this.modalSuccess.show();
			})
			.catch(error => console.error(error));
	}

	editVideo(video: Video){
		const { id } = video;
		this.videoService.get(id)
			.then(video => {
				this.videoEditForm.patchValue(video);
			})
			.catch(error => console.error);

		this.modalEdit.show();
	}

	watchVideo(video: Video){
		const { url } = video;
		this.urlVideoModal = url;
		this.modalVideo.show();
	}

	createVideoForm(){
		this.videoForm = this.fb.group({
			titulo: ['',Validators.required],
			marca: ['',Validators.required],
			url: ['',[ Validators.required,
				Validators.pattern(this.regex)
					]
				],
		});
	}

	createVideoEditForm(){
		this.videoEditForm = this.fb.group({
			id: ['',],
			titulo: ['',Validators.required],
			marca: ['',Validators.required],
			url: ['',[ Validators.required,
				Validators.pattern(this.regex)
					]
				],
			createdAt: ['',[]],
			updatedAt: ['',[]],
		});
	}

	onVideoFormSubmit(){
		const video:Video = this.videoForm.value;
		const timestamp = firebase.firestore.FieldValue.serverTimestamp();
		video.createdAt = timestamp;
		video.updatedAt = timestamp;

		this.loading = true;
		this.videoService.add(video).then( docRef => {
			console.log('%c Success! ', 'background: #222; color: #bada55');
			this.modalService.openModal();
			this.videoForm.reset();
			this.getAllVideos();
			this.loading = false;
			this.modalSuccess.show();
		})
		.catch(function(error) {
			console.error('%c Error! ', 'background: #f8d7da; color: #721c24 ');
			this.loading = false;
		});
	}

	onVideoEditFormSubmit(){
		const video:Video = this.videoEditForm.value;
		const timestamp = firebase.firestore.FieldValue.serverTimestamp();
		video.updatedAt = timestamp;

		this.loading = true;
		this.videoService
			.update(video.id, video)
			.then(() => {
				this.loading = false;
				this.modalEdit.hide();
				this.getAllVideos();
				this.modalSuccess.show();
				console.log('Successfully updated');
			})
			.catch((error) => console.error(error))
	}

	close(){
		this.modalService.modal.view = false;
	}

	videoModalUrl(){
		return this.videoModalUrl;
	}

	get titulo(){
		return this.videoForm.get('titulo');
	}

	get marca(){
		return this.videoForm.get('marca');
	}

	get url(){
		return this.videoForm.get('url');
	}

	get tituloInvalid(){
		return this.titulo.invalid && (this.titulo.dirty || this.titulo.touched);
	}

	get marcaInvalid(){
		return this.marca.invalid && (this.marca.dirty || this.marca.touched);
	}

	get urlInvalid(){
		return this.url.invalid && (this.url.dirty || this.url.touched);
	}

	get tituloEdit(){
		return this.videoEditForm.get('titulo');
	}

	get marcaEdit(){
		return this.videoEditForm.get('marca');
	}

	get urlEdit(){
		return this.videoEditForm.get('url');
	}

	get tituloEditInvalid(){
		return this.tituloEdit.invalid && (this.tituloEdit.dirty || this.tituloEdit.touched);
	}

	get marcaEditInvalid(){
		return this.marcaEdit.invalid && (this.marcaEdit.dirty || this.marcaEdit.touched);
	}

	get urlEditInvalid(){
		return this.urlEdit.invalid && (this.urlEdit.dirty || this.urlEdit.touched);
	}

}
