import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeccionNoticiaService } from '../services/index.services';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../modal.service';
import { ModalComponent } from '../shared/modal/modal.component';
import * as $ from 'jquery';
import * as _ from 'lodash';

export class Noticia {
  id: string;
  buttonUrl: string;
  leftUrl: string;
  rightUrl: string;
  texto: string;
  typeLeft: string;
  typeRight: string;
}
interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-seccion-noticias',
  templateUrl: './seccion-noticias.component.html',
  styleUrls: ['./seccion-noticias.component.css']
})
export class SeccionnoticiasComponent implements OnInit {
  noticia    : any = {
    leftUrl:'',
    rightUrl: '',
    buttonUrl: '',
    texto: '',
    typeLeft: '',
    typeRight: ''
  };
  payload = _.cloneDeep(this.noticia);
  private videoForm: FormGroup;
  public loading: boolean;
  showVideo = false;
  currentVideo = '';
  _from ='';
  constructor(
    private s: SeccionNoticiaService, 
    private fb: FormBuilder,
    public modalService: ModalService
  ) { }

  ngOnInit() {
    this.createVideoForm();
    // Vista del modal / Head del modal / Campos / Botones de accion
    this.modalService.buildModal(false,'Editar',this.videoForm.controls,true);
    this.getNoticia();
    
  }
  getNoticia(){
    this.s.getAll().then(noticias => {
      this.noticia = noticias[0];
      this.setModalValues();
      this.loading = false;
    })
    .catch(err => {
      console.error(err);
      this.loading = false;
    });
  }
  
  getThumb = (url) =>{
    return '//img.youtube.com/vi/' + this.getUrlIframe(url) + '/0.jpg';
  }
  getUrlIframe = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }


  createVideoForm(){
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    this.videoForm = this.fb.group({
      tipo: ['',Validators.required],
      url: ['',[ Validators.required,
        Validators.pattern(regExp)
          ]
        ],
      buttonUrl: [this.noticia.buttonUrl,Validators.required],
      texto: [this.noticia.texto,Validators.required]
    });
  }

  onFormSubmit(){
    this.payload = _.cloneDeep(this.noticia);
    var tipo = this.videoForm.get("tipo").value;
    switch (this._from) {
      case "table":
        this.payload.texto = this.videoForm.get("texto").value;
        this.payload.buttonUrl = this.videoForm.get("buttonUrl").value;
        this.updateNoticia();
        break;
      case "left":
        if(tipo == 'video'){
          this.payload.typeLeft = tipo;
          this.payload.leftUrl = this.videoForm.get("url").value;
          this.updateNoticia();
        }else{
          this.preUploadFile();
        }
        break;
      case "right":
        if(tipo == 'video'){
          this.payload.typeRight = tipo;
          this.payload.rightUrl = this.videoForm.get("url").value;
          this.updateNoticia();
        }else{
          this.preUploadFile();
        }
        break;
    }
  }
  updateNoticia(){
    this.s.update(this.noticia.id,this.payload).then(response => {
      this.getNoticia();
      this.close();
    })
    .catch(err => {
      console.error(err);
      this.loading = false;
    });
  }

  //file
  preUploadFile() {
    let inputElement: HTMLInputElement = document.getElementById(this.noticia.id) as HTMLInputElement;
    inputElement.click();
  }
  uploadFile($event: HTMLInputEvent, noticia__: any) {
    if(!$event.target.files.length) {
      console.log(`Upload file for brand has beend canceled`);
      return;
    }
    let file: File = $event.target.files[0];
    this.loading = true;
    this.s.upload(this.payload,file).then(response =>{
      if(this._from == "right"){
        this.payload.rightUrl = response;
        this.payload.typeRight = this.videoForm.get("tipo").value;
      }else{
        this.payload.leftUrl = response;
        this.payload.typeLeft = this.videoForm.get("tipo").value;
      }
      this.updateNoticia();
    })
    .catch(err => {
      console.error(err);
      this.loading = false;
    });
  }

  


  //modal
  setModalValues(){
    this.videoForm.get("texto").setValue(this.noticia.texto);
    this.videoForm.get("buttonUrl").setValue(this.noticia.buttonUrl);
  }

  close(){
    this.videoForm.get("tipo").setValue('');
    this.videoForm.get("url").setValue('');
    this.modalService.modal.view = false;
    let element = document.getElementById(this.noticia.id) as HTMLInputElement;
    element.value = "";
  }

  videoModalUrl(){
    return this.videoModalUrl;
  }

  get tipo(){
    return this.videoForm.get('tipo');
  }

  get url(){
    return this.videoForm.get('url');
  }

  get texto(){
    return this.videoForm.get('texto');
  }

  get buttonUrl(){
    return this.videoForm.get('buttonUrl');
  }

  get tipoInvalid(){
    return this.tipo.invalid && (this.tipo.dirty || this.tipo.touched);
  }

  get urlInvalid(){
    return this.url.invalid && (this.url.dirty || this.url.touched);
  }

  get textoInvalid(){
    return this.texto.invalid && (this.texto.dirty || this.texto.touched);
  }

  get buttonUrlInvalid(){
    return this.buttonUrl.invalid && (this.buttonUrl.dirty || this.buttonUrl.touched);
  }
  

}
