import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ModalComponent } from './shared/modal/modal.component';
import { AlertaService } from './services/alerta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  
  title = 'admin-agentes';

  @ViewChild(ModalComponent) alerta: ModalComponent;

  constructor(private alertService: AlertaService){  }

  ngAfterViewInit(): void {
    this.alertService.alerta = this.alerta;
  }

}
