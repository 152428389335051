import { Component, OnInit, AfterViewInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { ModalService } from '../modal.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainingService } from '../services/index.services';
//import { TrainingBannerService } from '../services/index.services';
import { Training } from '../models/index.models';
//import { TrainingBanner } from '../models/index.models';
import * as firebase from 'firebase';
import { ModalComponent } from '../shared/modal/modal.component';
import { environment } from 'src/environments/environment';
//import { AngularFireStorage } from '@angular/fire/storage';
//import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipeT implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
	let id: any;
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length == 11) {
		id = match[2];
    }
	const urlFinal = `${ id }`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit, AfterViewInit {
	// @ViewChild(ModalComponent) modal: ModalComponent;
	@ViewChildren(ModalComponent) modals: QueryList<ModalComponent>;
	private modalEdit: ModalComponent;
	public modalSuccess: ModalComponent;
	public modalVideo: ModalComponent;
	public urlVideoModal: any;
	private regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	private videoForm: FormGroup;
	//private bannerForm: FormGroup;
	private videoEditForm: FormGroup;
	public loading: boolean;
	public videos: Promise<Training[]>;
	//public trainingBanners: Promise<TrainingBanner[]>;
	//private firestoreTrainingBannerPath: string;
	//public bannerModalView = false;
	constructor(
		public modalService: ModalService,
		private fb: FormBuilder,
		//private fbb: FormBuilder,
		private videoService: TrainingService,
		//private trainingBannerService: TrainingBannerService,
		/*private firestorage: AngularFireStorage,
		private firestore: AngularFirestore,*/
		private sanitizer: DomSanitizer
	) {
		//this.firestoreTrainingBannerPath = `${environment.firestoreEnviroment}/training-banner`; 
	}

	ngOnInit() {
		this.createVideoForm();
		this.createVideoEditForm();
		//this.createBannerForm();
		this.getAllVideos();
		//this.getBanner();
		this.urlVideoModal = this.sanitizer.bypassSecurityTrustResourceUrl("");
		this.loading = false;
		// Vista del modal / Head del modal / Campos / Botones de accion
		this.modalService.buildModal(false,'Agregar Training',this.videoForm.controls,true);
	}

	ngAfterViewInit(): void {
		this.modalEdit = this.modals.find(modal => modal.identificador ==='miModal');
		this.modalSuccess = this.modals.find(modal => modal.identificador ==='miModalSuccess');
		this.modalVideo = this.modals.find(modal => modal.identificador ==='miModalVideo');
	}

	getAllVideos(){
		this.videos = this.videoService.getAll();
	}
	/*getBanner(){
		this.trainingBanners = this.trainingBannerService.getAll();
		this.bannerForm.get("urlBanner").setValue("");
	}*/

	/*banner*//*
	preUploadFileBanner(trainingBanner) {
		let inputElement: HTMLInputElement = document.getElementById(trainingBanner.id) as HTMLInputElement;
		inputElement.click();
	}
	uploadFileBanner($event: HTMLInputEvent , trainingBanner) {
		if(!$event.target.files.length) {
			console.log(`Upload file for brand ${trainingBanner.id} has beend canceled`);
			return;
		}
		let file: File = $event.target.files[0];
			trainingBanner.metadata = {
			file,
			uploadFlag: true,
			message: `File with name ${file.name} it's ready for upload. Please confirm`
		}
	}
	uploadConfirm(trainingBanner) {
		let file: File = trainingBanner.metadata.file;
		delete trainingBanner['metadata'];
		Promise.resolve(this.loading = true)
		.then(_ => this.firestorage.upload(`${environment.firestoreEnviroment}/training/${file.name}`, file))
		.then(_ => this.firestorage.ref(`${environment.firestoreEnviroment}/training/${file.name}`).getDownloadURL().toPromise())
		.then(urlImagen => this.firestore.collection(this.firestoreTrainingBannerPath).doc(trainingBanner.id).set({...trainingBanner, urlImagen}))
		.then(_ => this.loading = false)
		.then(_ => this.getBanner());
	}
	uploadReject(trainingBanner) {
		delete trainingBanner['metadata'];
	}
	openBannerModal(trainingBanner){
		this.bannerModalView = true;
		this.bannerForm.get("id").setValue(trainingBanner.id);
	}
	onBannerFormSubmit(){
		const url = this.bannerForm.get("urlBanner").value;
		const id = this.bannerForm.get("id").value;
		Promise.resolve(this.loading = true)
		.then(_ => this.firestore.collection(this.firestoreTrainingBannerPath).doc(id).update({url: url}))
		.then(_ => this.loading = false)
		.then(_ => this.bannerModalView = false)
		.then(_ => this.getBanner());
	}*/




	deleteVideo(video: Training){
		const { id } = video;
		this.videoService.delete(id)
			.then(() => {
				this.getAllVideos();
				this.modalSuccess.show();
			})
			.catch(error => console.error(error));
	}

	editVideo(video: Training){
		const { id } = video;
		this.videoService.get(id)
			.then(video => {
				this.videoEditForm.patchValue(video);
			})
			.catch(error => console.error);

		this.modalEdit.show();
	}

	watchVideo(video: Training){
		const { url } = video;
		this.urlVideoModal = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		this.modalVideo.show();
	}

	createVideoForm(){
		this.videoForm = this.fb.group({
			titulo: ['',Validators.required],
			marca: ['',Validators.required],
			url: ['',],
		});
	}
	/*createBannerForm(){
		this.bannerForm = this.fbb.group({
			id: ['',],
			urlBanner: ['',Validators.required]
		});
	}*/

	createVideoEditForm(){
		this.videoEditForm = this.fb.group({
			id: ['',],
			titulo: ['',Validators.required],
			marca: ['',Validators.required],
			url: ['',[ Validators.required,
				Validators.pattern(this.regex)
					]
				],
			createdAt: ['',[]],
			updatedAt: ['',[]],
		});
	}
	preUploadFile() {
		let inputElement: HTMLInputElement = document.getElementById("file") as HTMLInputElement;
		inputElement.click();
	}
	uploadFile($event: HTMLInputEvent) {
		this.loading = true;
		if(!$event.target.files.length) {
			console.log(`Upload file for brand has beend canceled`);
			return;
		}
		let file: File = $event.target.files[0];
		const ref = firebase.storage().ref();
		const name = (`${environment.firestoreEnviroment}`+'/training/' + file.name);
		const metadata = { contentType: file.type };
		const task = ref.child(name).put(file, metadata);
		return task.then(snapshot => snapshot.ref.getDownloadURL())
			.then((url) => {
				this.onVideoFormSubmit(url)
			})
			.catch((error) => {
				this.loading = false;
				console.error('Error en upload ',error);
				throw new Error(error);
			});
	}
	onVideoFormSubmit(url: string){
		const video:Training = this.videoForm.value;
		video.url = url;
		this.videoService.add(video).then( docRef => {
			console.log('%c Success! ', 'background: #222; color: #bada55');
			this.modalService.openModal();
			this.videoForm.reset();
			this.getAllVideos();
			this.loading = false;
			this.modalSuccess.show();
		})
		.catch(function(error) {
			console.error('%c Error! ', 'background: #f8d7da; color: #721c24 ');
			this.loading = false;
		});
	}

	onVideoEditFormSubmit(){
		const video:Training = this.videoEditForm.value;
		const timestamp = firebase.firestore.FieldValue.serverTimestamp();

		this.loading = true;
		this.videoService
			.update(video.id, video)
			.then(() => {
				this.loading = false;
				this.modalEdit.hide();
				this.getAllVideos();
				this.modalSuccess.show();
				console.log('Successfully updated');
			})
			.catch((error) => console.error(error))
	}

	close(){
		this.modalService.modal.view = false;
		//this.bannerModalView = false;
	}

	videoModalUrl(){
		return this.videoModalUrl;
	}

	get titulo(){
		return this.videoForm.get('titulo');
	}

	get marca(){
		return this.videoForm.get('marca');
	}

	get url(){
		return this.videoForm.get('url');
	}

	/*get urlBanner(){
		return this.bannerForm.get('urlBanner');
	}*/

	get tituloInvalid(){
		return this.titulo.invalid && (this.titulo.dirty || this.titulo.touched);
	}

	get marcaInvalid(){
		return this.marca.invalid && (this.marca.dirty || this.marca.touched);
	}

	get urlInvalid(){
		return this.url.invalid && (this.url.dirty || this.url.touched);
	}

	get tituloEdit(){
		return this.videoEditForm.get('titulo');
	}

	get marcaEdit(){
		return this.videoEditForm.get('marca');
	}

	get urlEdit(){
		return this.videoEditForm.get('url');
	}

	get tituloEditInvalid(){
		return this.tituloEdit.invalid && (this.tituloEdit.dirty || this.tituloEdit.touched);
	}

	get marcaEditInvalid(){
		return this.marcaEdit.invalid && (this.marcaEdit.dirty || this.marcaEdit.touched);
	}

	get urlEditInvalid(){
		return this.urlEdit.invalid && (this.urlEdit.dirty || this.urlEdit.touched);
	}

	/*get urlBannerInvalid(){
		return this.urlBanner.invalid && (this.urlBanner.dirty || this.urlBanner.touched);
	}*/

}
