import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class Marca {
  id: string;
  nombre: string;
  imageUrl: string;
  pdfUrl: string;
  metadata: any;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.css']
})
export class MarcasComponent implements OnInit {
  public marcas$: Observable<Marca[]>;
  private firestoreMarcasPath: string;
  public loading: boolean;

  constructor(
    private firestore: AngularFirestore,
    private firestorage: AngularFireStorage) {
      this.firestoreMarcasPath = `${environment.firestoreEnviroment}/marcas`;
    }

  ngOnInit() {
    this.marcas$ = this.firestore.collection<Marca>(this.firestoreMarcasPath)
                    .snapshotChanges().pipe(
                      map(snapshot => snapshot.map(x => ({ ...x.payload.doc.data(), id: x.payload.doc.id})))
                    )
  }

  preUploadFile(marca: Marca) {
    let inputElement: HTMLInputElement = document.getElementById(marca.id) as HTMLInputElement;
    inputElement.click();
  }

  uploadFile($event: HTMLInputEvent , marca: Marca) {
    if(!$event.target.files.length) {
      console.log(`Upload file for brand ${marca.id} has beend canceled`);
      return;
    }
    let file: File = $event.target.files[0];
    marca.metadata = {
      file,
      uploadFlag: true,
      message: `File with name ${file.name} it's ready for upload. Please confirm`
    }
  }

  uploadConfirm(marca: Marca) {
    let file: File = marca.metadata.file;
    delete marca['metadata'];
    Promise.resolve(this.loading = true)
      .then(_ => this.firestorage.upload(`${environment.firestoreEnviroment}/${marca.nombre}/marcaUpload/${file.name}`, file))
      .then(_ => this.firestorage.ref(`${environment.firestoreEnviroment}/${marca.nombre}/marcaUpload/${file.name}`).getDownloadURL().toPromise())
      .then(url => this.firestore.collection(this.firestoreMarcasPath).doc(marca.id).set({...marca, url}))
      .then(_ => this.loading = false);
  }

  /**
   * Metodo que elimina el archivo para la subida de datos.
   * @param marca Objeto que represena una marca de RoyalAgentes
   */
  uploadReject(marca: Marca) {
    marca.metadata = {};
    let element = document.getElementById(marca.nombre) as HTMLInputElement;
    element.value = "";
  }

}
