import { Injectable } from '@angular/core';
import { Noticia } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';
import { GenericV2Service } from './generic-v2.service';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService extends GenericV2Service<Noticia>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore);
    super.setActionUrl('noticias')
  	// super('noticias')
  }
}
