import { Injectable } from '@angular/core'
import { GenericV2Service } from './generic-v2.service';
import { Material } from '../models/index.models'
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class MaterialesService extends GenericV2Service<Material>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore);
    super.setActionUrl('materiales');
  	// super('materiales')
  }
}
