import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Video } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends GenericV2Service<Video>{

  constructor(firestore: AngularFirestore) {
    super(firestore)
    super.setActionUrl('videos')
    // super('videos');
  }
}
