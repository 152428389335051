import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
	modal = {
		view: false,
		head: '',
		campos: [],
		buttons: true
	}

  	constructor() { }

  	buildModal(view,head,campos,buttons){
  		this.modal = {
  			view: view,
  			head: head,
			campos: campos,
			buttons: buttons
  		}
  	}

  	openModal(){
  		if(this.modal.view == true){
  			this.modal.view = false
  		}else{
  			this.modal.view = true;  			
  		}
  	}
}
