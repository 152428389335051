import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NoticiasService } from '../services/index.services';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import { environment } from 'src/environments/environment';

moment.locale('es');

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {

	noticiaForm : FormGroup;
  noticiaFile : any;
  success     : boolean = false;
  loading     : boolean = false;
  noticias    : any = [];

  constructor(
  	public modalService: ModalService,
  	private fb: FormBuilder,
    private s: NoticiasService
  ) { 

  }

  ngOnInit() {
  		this.noticiaForm = this.fb.group({
  			titulo: ['',Validators.required],
  			fecha: ['',Validators.required],
        file: [null, Validators.required]
  		})        
  	  this.getAllNoticias();								// Vista del modal / Head del modal / Campos / Botones de accion
  		this.modalService.buildModal(false,'Agregar Noticia',this.noticiaForm.controls,true);
  }
  
  getAllNoticias(){
    this.s.getAll().then( r =>{        
      _.forEach(r, e =>{
        e.dateFormated = moment.utc(e.datePost.toDate()).format('LL')
      })
      r = _.sortBy( r , e =>{
        return e.dateFormated
      }).reverse();
      this.noticias = r;
    })
  }

  postNoticia(){    
    if(this.noticiaForm.value){
      this.loading = true;
      let urlRef = `${environment.firestoreEnviroment}`+'/noticias/' + this.noticiaFile.name;
      let ref = firebase.storage().ref().child(urlRef);      
      ref.put(this.noticiaFile).then( snapshot => {
        snapshot.ref.getDownloadURL().then( urlUploaded => {
          this.s.add({
            titulo     : this.noticiaForm.controls.titulo.value,
            datePost   : new Date(this.noticiaForm.controls.fecha.value),
            documentUrl: urlUploaded,
            urlRef
          }).then( r => {
            console.log(r)
            this.noticiaForm.reset();
            this.modalService.openModal();
            this.success = true;
            this.loading = false;
            this.getAllNoticias();
          }).catch( e => {
             this.loading = false;
          })
        })
      }).catch( e => {
        this.loading = false;
      })
    }
  }  

  updateFile($event){    
    if($event.target.files && $event.target.files[0]){
      this.noticiaFile = $event.target.files[0];
    }
  }  

  deletePost(noticia){
    this.loading = true;
    if(noticia){
      let ref = firebase.storage().ref().child(noticia.urlRef);
      ref.delete().then( () => {
        this.s.delete(noticia.id).then( r => {
          this.loading = false;
          this.success = true;
          this.getAllNoticias();
        }).catch( e => {
          this.loading = false;        
        })
      }).catch( () => {
        this.loading = false;
      })        
    }
  }

  private buildForm(){
  	this.noticiaForm = this.fb.group({
  		titulo: ['',Validators.required],
  		fecha: ['',Validators.required],
  	})
  }
}
