export { VideoService } from "./video.service"
export { NoticiasService } from "./noticias.service"
export { MaterialesService } from "./materiales.service"
export { ComunicadosService } from "./comunicados.service"
export { ClientesService } from "./clientes.service"
export { MayoristasService } from './mayoristas.service'
export { BannerV2Service } from './banner-v2.service'
export { SeccionNoticiaService } from './seccion-noticia.service'
export { TrainingService } from './training.service'
//export { TrainingBannerService } from './training-banner.service'
