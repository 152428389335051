import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

class Pdf {
  id: string;
  fullname: string;
  grupo: string;
  name: string;
  url: string;
  metadata: any;
}

@Component({
  selector: 'app-pdf-administrables',
  templateUrl: './pdf-administrables.component.html',
  styleUrls: ['./pdf-administrables.component.css']
})
export class PdfAdministrablesComponent implements OnInit {
  public pdfCollection: Array<Pdf>;
  public PDF_PATH: string;
  public loading: boolean;

  constructor(
    private firestore: AngularFirestore,
    private firestorage: AngularFireStorage
  ) {
    this.PDF_PATH = `${environment.firestoreEnviroment}`+'/pdfs'
  }

  ngOnInit() {
    this.loadPdfFirestoreCollection();
  }


  firestoreUploadPdf(file) {

  }

  loadPdfFirestoreCollection() {
    let firestore = this.firestore;
    return firestore.collection(this.PDF_PATH).snapshotChanges().subscribe(querySnapshot => {
      this.pdfCollection = querySnapshot.map(snapshot => {
        let data: any = snapshot.payload.doc.data()
        return {
          id: snapshot.payload.doc.id,
          fullname: data.nombre,
          grupo: data.nombre.replace('/assets/pdf/', '').replace('grupos/', '').split('/')[0],
          name: data.nombre.replace('/assets/pdf/', '').replace('grupos/', '').split('/')[1],
          url: data.url,
          metadata: {}
        }
      })
    })
  }

  updateReject(pdf: Pdf) {
    pdf.metadata = {};
    let elemet: HTMLInputElement = document.getElementById(pdf.id) as HTMLInputElement;
    elemet.value = "";
  }

  updateConfirm(pdf: Pdf) {
    let file: File = pdf.metadata.file;
    Promise.resolve(this.loading = true)
      .then(() => this.firestorage.upload(`${environment.firestoreEnviroment}`+`/pdf/${file.name}`, file))
      .then(success => this.firestorage.ref(`${environment.firestoreEnviroment}`+`/pdf/${file.name}`).getDownloadURL().toPromise())
      .then(url => this.firestore.collection(this.PDF_PATH).doc(pdf.id).set({ nombre: pdf.fullname, url }))
      .then(success => this.loading = false)
  }

  uploadFile(event, pdf: Pdf) {
    if (!event.target.files.length) {
      console.log(`Upload file for ${pdf.name} has been canceled`);
      return;
    }
    let file: File = event.target.files[0];
    pdf.metadata.message = `Archivo ${file.name} listo para ser subido. Favor de confirmar`
    pdf.metadata.file = file;
  }

  uploadPdf(id: string) {
    let input: HTMLElement = document.getElementById(id);
    input.click();
  }
}

