import { Injectable } from '@angular/core';
import { GenericV2Service } from './generic-v2.service';
import { Comunicado } from '../models/index.models';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class ComunicadosService extends GenericV2Service<Comunicado>{

  constructor(protected firestore: AngularFirestore) {
    super(firestore)
    super.setActionUrl('comunicados')
  	// super('comunicados')
  }
}
