import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { AlertaService } from '../services/alerta.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { environment } from 'src/environments/environment';

import * as _ from 'lodash';
import * as moment from 'moment';
import { BannerV2Service } from '../services/index.services';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})
export class PromocionesComponent implements OnInit {

//IMPORTANT: This component needs endpoint '/banners' with 3 items having a property marc: 'Celebrity' or 'Royal' or 'Azamara' each one.
  public loading: boolean;
  private alertSuccess: ModalComponent;
  private imageCelebrity: Promise<any>;
  private imageRoyal: Promise<any>;
  private imageAzamara: Promise<any>;
  private banners;
  private bannersLimit;
  private numBanners;
  private addButt;
  constructor(private bannerService: BannerV2Service, private alertService: AlertaService) { }

  ngOnInit() {
    this.loading = false;
    this.alertSuccess = this.alertService.alerta;
    this.renderImage('Celebrity');
    this.renderImage('Royal');
    this.renderImage('Azamara');

    this.getAllBanners();
    this.bannersLimit = this.banners.__zone_symbol__value.length < 10;
    this.numBanners = this.banners.__zone_symbol__value.length - 1;
    this.addButt = true;
  }

  async getAllBanners(){
    this.banners = this.bannerService.getAll();
  }

  async renderImage(marca:string){

    switch (marca) {
      case 'Celebrity':
        this.imageCelebrity = this.bannerService.getImagebyMarc(marca);
        break;

      case 'Royal':
      this.imageRoyal = this.bannerService.getImagebyMarc(marca);
        break;

      case 'Azamara':
      this.imageAzamara = this.bannerService.getImagebyMarc(marca);
        break;

      default:
      console.error('No hay marca..');
        break;
    }

  }

  onImageChange($event, marc:string){
    const validTypes = ["image/gif", "image/jpeg", "image/png"];

    if($event.target.files && $event.target.files.length > 0) {

      const file = $event.target.files[0];
      const type = file.type;

      if(!validTypes.find(element => element === type )){
        console.error("Formato de Imagen no valido");
        return;
      }

      // this.loading =true;
      this.getUrlandUpdate(file,marc,'image')
          .then(response => {
            console.log("Image Posted Succesfully!", marc);
            this.loading = false;
            this.renderImage(marc);
            this.getAllBanners();
            this.alertSuccess.show();
          })
          .catch(error => {
            console.log("Image NOT Posted Succesfully!");
            this.loading = false;
            console.error(error);
          });

    }
  }

  onFileChange($event, marc:string){
    const validTypes = ["application/pdf"];

    if($event.target.files && $event.target.files.length > 0) {

      const file = $event.target.files[0];
      const type = file.type;

      if(!validTypes.find(element => element === type )){
        console.error("Formato de Imagen no valido");
        return;
      }

      this.loading =true;
      this.getUrlandUpdate(file,marc,'file')
          .then(response => {
            this.loading = false;
            console.log('File ', response);
            this.alertSuccess.show();
          })
          .catch(error => {
            this.loading = false;
            console.error(error);
          });

    }
  }

  async getUrlandUpdate(file,marc:string,type:string){
    try {
      const url = await this.uploadAndGetUrl(file);
      const id = await this.bannerService.getBannerbyMarc(marc);
      let response: any;
      if (type === 'image') {
        response = await this.bannerService.update(id.toString(),{urlImage: url});
      }
      else{
        response = await this.bannerService.update(id.toString(),{urlFile: url});
      }
      return `Posted Successfuly`;
    } catch (error) {
      throw new Error(error);
    }
  }

  async uploadAndGetUrl(file){
    try {
      const url = await this.upload(file);
      return url;
    } catch (error) {
      throw new Error(error);
    }
  }

  async upload(file){
    const ref = firebase.storage().ref();
    const name = (`${environment.firestoreEnviroment}`+'/banners/' + file.name);
    const metadata = { contentType: file.type };
    const task = ref.child(name).put(file, metadata);

    return task.then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          return url;
        })
        .catch((error) => {
          console.error('Error en upload ',error);
          throw new Error(error);
        });
  }

  async newBanner(){
    this.banners.__zone_symbol__value.push({marc : +this.banners.__zone_symbol__value.length, urlFile : "", urlImage: "", pos : this.banners.__zone_symbol__value.length});
    this.bannersLimit = this.banners.__zone_symbol__value.length < 10;
    this.numBanners = this.banners.__zone_symbol__value.length - 1;
    this.saveBanner({marc : +this.banners.__zone_symbol__value.length, urlFile : "", urlImage: "", pos : this.banners.__zone_symbol__value.length});
    this.addButt = false;

  }

  // async removeBanner(index){
  //   this.banners.__zone_symbol__value.splice(index,1);
  //   this.bannersLimit = this.banners.__zone_symbol__value.length < 10;
  //   this.numBanners = this.banners.__zone_symbol__value.length - 1;
  // }

  async delete(id){
    // var pos = (+i) - 1;
    // this.banners.__zone_symbol__value.splice(pos,1);
    // console.log();
    // return;
    // this.removeBanner();
    this.bannerService.deleteById(id);
    this.getAllBanners();
  }

  async saveBanner(banner){
    this.bannerService.addBanner(banner);
    this.addButt = true;
    this.getAllBanners();
  }
}
