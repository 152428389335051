import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComunicadosService } from '../services/index.services';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/es';
import { environment } from 'src/environments/environment';

moment.locale('es');

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.css']
})
export class ComunicadosComponent implements OnInit {
  comunicadoForm : FormGroup;
  comunicadoFile : any;
  success     : boolean = false;
  loading     : boolean = false;
  comunicados    : any = [];

  constructor(
  	public modalService: ModalService,
  	private fb: FormBuilder,
    private s: ComunicadosService
  ){  		
	}

  	ngOnInit() {
  		this.comunicadoForm = this.fb.group({
  			titulo: ['',Validators.required],
  			category: ['',Validators.required],
  			fecha: ['',Validators.required],
        file: [null, Validators.required]
  		})
  		this.getAllComunicados();
  		this.modalService.buildModal(false,'Agregar Comunicado',this.comunicadoForm.controls,true);
  	}

    getAllComunicados(){
      this.s.getAll().then( r =>{   
         _.forEach(r, e =>{
          e.dateFormated = moment.utc(e.datePost.toDate()).format('LL')
        })
        r = _.sortBy( r , e =>{
          return e.dateFormated
        }).reverse();     
        this.comunicados = r;
      })
    }

    postComunicado(){    
      if(this.comunicadoForm.value){
        this.loading = true;
        let urlRef = `${environment.firestoreEnviroment}`+'/comunicados/' + this.comunicadoFile.name;
        let ref = firebase.storage().ref().child(urlRef);      
        ref.put(this.comunicadoFile).then( snapshot => {
          snapshot.ref.getDownloadURL().then( urlUploaded => {
            this.s.add({
              titulo     : this.comunicadoForm.controls.titulo.value,
              datePost   : new Date(this.comunicadoForm.controls.fecha.value),
              documentUrl: urlUploaded,
              category   : this.comunicadoForm.controls.category.value,
              urlRef
            }).then( r => {
              console.log(r)
              this.comunicadoForm.reset();
              this.modalService.openModal();
              this.success = true;
              this.loading = false;
              this.getAllComunicados();
            }).catch( e => {
               this.loading = false;
            })
          })
        }).catch( e => {
          this.loading = false;
        })
      }
    }

    deletePost(comunicado){
      this.loading = true;
      if(comunicado){
        let ref = firebase.storage().ref().child(comunicado.urlRef);
        ref.delete().then( () => {
          this.s.delete(comunicado.id).then( r => {
            this.loading = false;
            this.success = true;
            this.getAllComunicados();
          }).catch( e => {
            this.loading = false;        
          })
        }).catch( () => {
          this.loading = false;
        })        
      }
    }

    updateFile($event){    
      if($event.target.files && $event.target.files[0]){
        this.comunicadoFile = $event.target.files[0];
      }
    }    

  	private buildForm(){
  		this.comunicadoForm = this.fb.group({
  			titulo: ['',Validators.required],
  			categoria: ['',Validators.required],
  			fecha: ['',Validators.required]
  		})
  	}

}
