import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() identificador: string;
  private hid: string;
  public open: boolean;

  constructor() { }

  ngOnInit() {
    this.hid = "#" + this.identificador;
    this.open = false;
  }

  onConfirmacion = ():any =>{
    console.log('Confirmado...');
    return false;
  }

  show(){
    this.open = true;
  }

  hide(){
    this.open = false;
  }

  confirmar(validate: () => any = this.onConfirmacion){
    this.hide();
    validate();
  }

}
