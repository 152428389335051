import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private afAuth : AngularFireAuth, private router: Router, private auth : AuthService) { }
  loading = true;
  ngOnInit() {
  	
  	setTimeout(() =>{   		
  		if(!this.auth.authenticated()){
	  		this.router.navigate(['/login'])
	  	}else{
	  		this.loading = false;
	  	}
	  }, 2000);  	
  }

  logout(){
  	this.afAuth.auth.signOut().then(r =>{  		
  		this.router.navigate(['/login'])
  	})
  }

}
